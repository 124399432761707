/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { string, object, arrayOf } from 'prop-types'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import favicon from 'assets/images/favicon.ico'

function SEO({ intl, description, meta, keywords, title }) {
  const metaDescription =
    description || intl.formatMessage({ id: 'seo.description' })
  const metaKeywords = `${keywords && `${keywords},`}${intl.formatMessage({
    id: 'seo.keywords'
  })}`

  return (
    <Helmet
      htmlAttributes={{
        lang: intl.locale
      }}
      title={title}
      defaultTitle={intl.formatMessage({ id: 'seo.title' })}
      titleTemplate={`%s | ${intl.formatMessage({ id: 'seo.title' })}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          name: 'keywords',
          content: metaKeywords
        }
      ].concat(meta)}
    >
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
  )
}

SEO.propTypes = {
  intl: object.isRequired,
  description: string,
  meta: arrayOf(object),
  keywords: string,
  title: string
}

SEO.defaultProps = {
  meta: [],
  title: '',
  keywords: '',
  description: ''
}

export default injectIntl(SEO)
